/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    string,
    func,
    bool,
    number,
    object,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ZipInputField from './Partials/ZipInputField';
import AddressZipField from './AddressZipField';
import useSalesforcePersonalizedContentQuery from '../../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import AutoFillAddressSkeleton from '../../../GraphqlSkeletonComponents/AutoFillAddressSkeleton';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const GiftFindZipCodeBlock = ({
    zipcodeIsValid, zipLabel, zipOrAddressInputPlaceholder, giftFinderBodyTextColor,
    currentInputLength, value, handleChange,
    onSubmitCheck, userSubmittedZip, brandDomain,
    giftFinderCodeTheoryView, inputBorderColor, giftFinderFieldStyle, classes,
}) => {
    const dispatch = useDispatch();
    const isGiftFinderAbAutofill = useSelector(getFeatureFlag('is-gift-finder-ab-autofill-test'));
    const forceAddressOrZipInputVariant = useSelector(getFeatureFlag('force-address-or-zip-input-variant'));
    const { data, loading, variables } = useSalesforcePersonalizedContentQuery({
        queryName: ' gfAutoFillAddress',
        interactionName: `${brandDomain} - Get Campaign - Gift Finder Auto Fill Address`,
        featureFlag: isGiftFinderAbAutofill,
        optInHoldoutParticipation: false,
        resolveIdentity: false,
    });

    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: { type: 'homepage' },
    });

    const gfAutoFillAddressUserGroup = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;
    const gfAutoFillAddressVariant = gfAutoFillAddressUserGroup?.toLowerCase?.() === 'test' || forceAddressOrZipInputVariant;

    useEffect(() => {
        if (typeof gfAutoFillAddressUserGroup !== 'undefined' && !window.wuAutoFillEventFired && gfAutoFillAddressUserGroup && isGiftFinderAbAutofill) {
            // GA tracking
            const eventParam = {
                eventCategory: 'Experiment',
                eventAction: 'Gift Finder Auto Fill Address ',
                eventLabel: gfAutoFillAddressVariant ? 'Variant' : 'Control',
                feature_detail: gfAutoFillAddressVariant ? 'Variant' : 'Control',
                feature_element: 'Gift Finder Auto Fill Address',
                feature_category: 'Experiment',
                experiment_id: data?.contentSF?.campaign?.campaignResponses?.[0]?.campaignId,
            };
            dispatch(trackEvent(eventParam));
            window.wuAutoFillEventFired = true;
        }
        return () => {
            window.wuAutoFillEventFired = false;
        };
    }, [gfAutoFillAddressUserGroup]);

    // if (loading || variables?.skip) {
    //     return <AutoFillAddressSkeleton />;
    // }

    return (
        <div className={`${classes.zipContainer} ${gfAutoFillAddressVariant ? classes.addressWrapper : ''}`}>
            {gfAutoFillAddressVariant
                ? (
                    <AddressZipField
                        zipLabel={zipLabel}
                        zipOrAddressInputPlaceholder={zipOrAddressInputPlaceholder}
                        giftFinderBodyTextColor={giftFinderBodyTextColor}
                        gfAutoFillAddressVariant={gfAutoFillAddressVariant}
                        ziphandleChange={handleChange}
                        giftFinderCodeTheoryView={giftFinderCodeTheoryView}
                        giftFinderFieldStyle={giftFinderFieldStyle}
                        inputBorderColor={inputBorderColor}
                        onSubmitCheck={onSubmitCheck}
                        isLoadingOrVariableSkip={loading || variables?.skip}
                    />
                )
                : (
                    <ZipInputField
                        zipLabel={zipLabel}
                        currentInputLength={currentInputLength}
                        handleChange={handleChange}
                        onSubmitCheck={onSubmitCheck}
                        zipcodeIsValid={zipcodeIsValid}
                        userSubmittedZip={userSubmittedZip}
                        value={value}
                        giftFinderBodyTextColor={giftFinderBodyTextColor}
                        giftFinderCodeTheoryView={giftFinderCodeTheoryView}
                        giftFinderFieldStyle={giftFinderFieldStyle}
                        inputBorderColor={inputBorderColor}
                        isLoadingOrVariableSkip={loading || variables?.skip}
                    />
                )}
        </div>
    );
};

GiftFindZipCodeBlock.propTypes = {
    zipLabel: string.isRequired,
    zipOrAddressInputPlaceholder: string,
    currentInputLength: number.isRequired,
    handleChange: func.isRequired,
    onSubmitCheck: bool.isRequired,
    zipcodeIsValid: bool,
    userSubmittedZip: string.isRequired,
    value: string.isRequired,
    giftFinderBodyTextColor: string,
    brandDomain: string.isRequired,
    giftFinderCodeTheoryView: bool,
    giftFinderFieldStyle: object,
    inputBorderColor: string,
    classes: object,
};

GiftFindZipCodeBlock.defaultProps = {
    zipcodeIsValid: null,
    zipOrAddressInputPlaceholder: '',
    giftFinderBodyTextColor: '',
    giftFinderCodeTheoryView: false,
    giftFinderFieldStyle: {},
    inputBorderColor: '',
    classes: {},
};

export default GiftFindZipCodeBlock;

/*
  * Confidential and Proprietary.
  * Do not distribute without 1-800-Flowers.com, Inc. consent.
  * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
  */
import React, { useEffect } from 'react';

import {
    FormControl, Input, InputLabel, NativeSelect,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
    array, func, object, string,
} from 'prop-types';
import CUSTOM_BREAKPOINTS from '../../../../../helpers/customBreakpoints';

const useStyles = makeStyles((theme) => ({
    zipContainer: {
        flex: '100%',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flex: '1 0 110px',
            marginRight: '8px',
            minWidth: '130px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            flex: '0 0 160px',
            marginRight: '16px',
        },
        '& input': {
            opacity: '1 !important',
            '&:focus-visible': {
                outline: '-webkit-focus-ring-color auto 1px',
                borderRadius: '3px',
            },
        },
    },
    label: {
        color: '#595959',
        letterSpacing: '0.42px',
        fontSize: '16px',
        '&$labelFocus': {
            color: '#595959',
        },
        [theme.breakpoints.down(325)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        // tab and desktop
        [theme.breakpoints.up(768)]: {
            color: '#595959',
            fontSize: '14px',
            fontWeight: 300,
        },

    },
    labelFocus: {},
    shrink: {
        [theme.breakpoints.up(768)]: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    },
    select: {
        color: theme.palette.gfMobileSelectOptionTextColor,
        letterSpacing: '0.42px',
        fontSize: theme.typography?.fontSize || '16px',
        '&:focus-visible': {
            backgroundColor: 'rgba(0,0,0,0)',
            color: theme.palette.bgHeader, // TO DO: Need palette primary
            outline: '-webkit-focus-ring-color auto 1px',
            borderRadius: '3px',
        },
        height: '2rem',
        backgroundColor: theme.palette.white,
        padding: '0 22px 0 10px',
        border: theme.palette.gfTabDesktopInputBorder,
        borderRadius: '3px',
        '&:focus': {
            borderRadius: '3px',
            backgroundColor: theme.palette.white,
        },

        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
    },
    arrowIcon: {
        fontSize: '33px',
        top: 'calc(50% - 17px)',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            fontSize: '30px',
            top: 'calc(50% - 15px)',
        },
    },
    inputMarginTop: {
        'label + &': {
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                marginTop: '22px',
            },
        },
    },
    ctSelect: {
        [theme.breakpoints.down(768)]: {
            fontSize: theme.palette?.text?.fontSize18 || '18px',
            marginTop: '20px !important',
            background: '#FFF !important',
            border: '1px solid rgb(0, 0, 0)',
            borderRadius: '4px',
            boxShadow: 'none',
            letterSpacing: 'unset',
            '& input, & select': {
                height: '46px',
                padding: '0 12px 2px 12px',
                fontSize: theme.typography?.fontSize || '16px',
                color: '#000',
                borderRadius: '4px',
                border: '1px solid transparent',
                boxShadow: 'none',
                outline: 0,
                '&:focus, &:active': {
                    borderRadius: '4px',
                    border: '1px solid #262626',
                    boxShadow: 'none',
                    outline: 0,
                },
            },
            '& svg': {
                color: '#222222',
                width: '40px',
                height: '40px',
                top: 'calc(50% - 20px)',
            },
            '&.Mui-focused': {
                background: 'transparent',
            },
        },
    },
}));

const GiftFinderOccasionField = ({
    occasionOption, setSelectedOccasion,
    occasionTitle,
    occasion, inputLabelColor,
}) => {
    const classes = useStyles();

    return (
        <FormControl fullWidth>
            <InputLabel
                shrink
                htmlFor="occasion-native-label-placeholder"
                classes={{
                    root: `${classes.label}`,
                    shrink: classes.shrink,
                    focused: classes.labelFocus,
                }}
                style={{ color: inputLabelColor }}
            >
                {occasionTitle}
            </InputLabel>
            <NativeSelect
                disableUnderline
                value={occasion?.name}
                onChange={(e) => {
                    setSelectedOccasion(e.target.value);
                }}
                classes={{
                    root: classes.selectMarginTop,
                    select: classes.select,
                    icon: classes.arrowIcon,
                }}
                className={classes.ctSelect}
                input={(
                    <Input
                        name="occasion"
                        id="occasion-native-label-placeholder"
                        data-test="4810-hp-occasion-native-label-placeholder"
                        classes={{
                            root: classes.inputMarginTop,
                        }}
                    />
                )}
            >
                {occasionOption?.map((d) => (
                    <option
                        value={d.option_name}
                        key={d.option_name}
                    >
                        {d.option_name}
                    </option>
                ))}
            </NativeSelect>
        </FormControl>
    );
};
GiftFinderOccasionField.propTypes = {
    setSelectedOccasion: func.isRequired,
    occasionOption: array.isRequired,
    inputLabelColor: string.isRequired,
    occasionTitle: string.isRequired,
    occasion: object.isRequired,
};

export default GiftFinderOccasionField;

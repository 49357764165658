/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { TextField, makeStyles, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import {
    bool, string, fun, object,
} from 'prop-types';
import QasSuggestionList from '../../../GraphqlCategoryPage/Partials/GraphqlLeftMenuContainer/Partials/QasSuggestionList';
import { getFeatureFlags } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import QasClient from '../../../../../../apis/qas-address-search-apis/qas-address-search-apis';
import {
    clearUserSubmittedProductFilterZipcode,
    clearValidatedZipcode, resetProductFilterZipcodeValidity, setAutoFillAddress, setUserSelectedAddress, setUserSubmittedProductFilterZipcode, validateProductFilterZipcode,
} from '../../../../../../state/ducks/App/App-Actions';
import GoogleAddressSearch from '../../../GraphqlCommonComponents/GoogleAddressSearch';
import {
    getAutoFillAddress,
    getIsProductFilterZipcodeValid, getSavedAddress, getUserSubmittedProductFilterZipcode, getValidatedZipcode,
} from '../../../../../../state/ducks/App/App-Selectors';
import CUSTOM_BREAKPOINTS from '../../../../../helpers/customBreakpoints';

const useStyles = makeStyles((theme) => ({
    zipInputRoot: {
        borderBottom: theme.palette.gfMobileInputBorderBottom,
        width: '300px',
        marginBottom: 14,
        '&::before': {
            position: 'absolute',
            zIndex: '998',
            color: theme.palette.white,
            lineHeight: '25px',
            fontSize: theme.palette?.text?.fontSize11 || '11px',
            fontWeight: '300',
            content: '"Invalid zip code"',
            bottom: '-25px',
            left: 0,
            paddingLeft: '7px',
            width: '100%',
            height: '25px',
            backgroundColor: theme.palette.colorAlert,
            transition: 'opacity 0.3s ease-in',
            opacity: 0,
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            borderBottom: 'none',
            marginBottom: 0,
        },
        '& input': {
            opacity: '1',
            fontSize: theme.typography?.fontSize || '16px',
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                height: '32px',
                backgroundColor: theme.palette.white,
                padding: '0 12px 0 10px',
                fontSize: theme.palette?.text?.fontSize12 || '12px',
                border: theme.palette.gfTabDesktopInputBorder,
                borderRadius: '3px',
                '&::placeholder': {
                    color: '#666666',
                    opacity: '1',
                },
            },
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
                fontSize: theme.palette?.text?.fontSize14 || '14px',
            },
        },
        [theme.breakpoints.down(1024)]: {
            width: '100%',
        },

    },
    paddingRight: {
        '& input': {
            '&:focus': {
                outline: '1px solid',
            },
        },
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            paddingRight: 13,
        },
    },
    addOpacity: {
        '&::before': {
            opacity: 1,
        },
    },
    zipLabelRoot: {
        fontSize: theme.typography?.fontSize || '16px',
        color: theme.palette.gfMobileLabelTextColor || '#212121',
        letterSpacing: '0.42px',
        zIndex: 9,
        [theme.breakpoints.down(325)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            color: theme.palette.white,
            fontSize: theme.palette?.text?.fontSize12 || '12px',
            fontWeight: 300,
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        '&:hover': {
            borderBottom: 'none',
        },
        '&$zipLabelFocused': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                color: theme.palette.white,
            },
        },
    },
    zipLabelWarning: {
        '&::after': {
            position: 'absolute',
            zIndex: '999',
            backgroundColor: theme.palette.colorAlert,
            color: theme.palette.white,
            textAlign: 'center',
            content: '"!"',
            fontSize: theme.palette?.text?.fontSize11 || '11px',
            fontWeight: 'bold',
            lineHeight: '16px',
            right: '-20px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            animation: '$fadeZoomIn 0.3s ease-in',
        },
        '&$zipLabelFocused': {
            color: theme.palette.colorAlert,
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                color: theme.palette.white,
            },
        },
    },
    ctZipErrorText: {
        '&::after': {
            position: 'relative',
            marginLeft: '7px',
            zIndex: '999',
            color: theme.palette.colorAlert,
            textAlign: 'center',
            content: '"Invalid ZIP code"',
            fontSize: theme.typography?.fontSize || '16px',
            lineHeight: '16px',
            animation: '$fadeZoomIn 0.3s ease-in',
        },
        '& + div > input, & + div > input:focus, & + div > input:active': {
            boxShadow: `0px 0px 0px 1px ${theme.palette.colorAlert}`,
        },
    },
    zipLabelSuccess: {
        '&::after': {
            position: 'absolute',
            zIndex: '999',
            backgroundColor: theme.palette.colorSuccess,
            color: theme.palette.white,
            textAlign: 'center',
            content: '"✓"',
            fontSize: theme.palette?.text?.fontSize10 || '10px',
            fontWeight: 'bold',
            lineHeight: '15px',
            right: '-20px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            animation: '$fadeZoomIn 0.3s ease-in',
        },
    },
    zipLabelFocused: {},
    shrink: {
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    },
    inputMarginTop: {
        'label + &': {
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                marginTop: '22px',
            },
        },
    },
    inputMarginTopGift: {
        'label + &': {
            marginTop: '13px',
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                marginTop: '13px',
            },
        },
    },
    ctInputMarginTopGift: {
        'label + &': {
            marginTop: '20px',
        },
    },
    '@keyframes fadeZoomIn': {
        '0%': { opacity: 0, transform: 'scale3d(0.3, 0.3, 0.3)' },
        '100%': { opacity: 1 },
    },
    zipLabelGiftFinderFocused: {
        fontSize: '16px',
    },
    ctInputLabelZipMobile: {
        color: '#595959',
        lineHeight: '16px',
        letterSpacing: '0.42px',
        fontWeight: 'normal',
        fontStyle: 'normal',
    },
    ctSelect: {
        [theme.breakpoints.down(768)]: {
            fontSize: theme.palette?.text?.fontSize18 || '18px',
            marginTop: '20px !important',
            background: '#FFF !important',
            border: '1px solid rgb(0, 0, 0)',
            borderRadius: '4px',
            boxShadow: 'none',
            letterSpacing: 'unset',
            '& input, & select': {
                height: '46px',
                padding: '0 12px 2px 12px',
                fontSize: theme.typography?.fontSize || '16px',
                color: '#000',
                borderRadius: '4px',
                border: '1px solid transparent',
                boxShadow: 'none',
                outline: 0,
                '&:focus, &:active': {
                    borderRadius: '4px',
                    border: '1px solid #262626',
                    boxShadow: 'none',
                    outline: 0,
                },
            },
            '& svg': {
                color: '#222222',
                width: '40px',
                height: '40px',
                top: 'calc(50% - 20px)',
            },
            '&.Mui-focused': {
                background: 'transparent',
            },
        },
    },
}));
const AddressZipField = ({
    zipLabel, zipOrAddressInputPlaceholder, giftFinderBodyTextColor, gfAutoFillAddressVariant,
    ziphandleChange, giftFinderCodeTheoryView, giftFinderFieldStyle, onSubmitCheck, isLoadingOrVariableSkip,
}) => {
    const classes = useStyles();
    const [qasFailed, setQasFailed] = useState(false);
    const [showQasModel, setShowQasModel] = useState(false);
    const featureFlags = useSelector(getFeatureFlags);
    const userSubmittedZip = useSelector(getUserSubmittedProductFilterZipcode);
    const [loadGoogleMaps, setLoadGoogleMaps] = useState(false);
    const [currentAddress, setCurrentAddress] = useState('');
    const [qasSuggestions, setQasSuggestions] = useState({});
    const validatedZip = useSelector(getValidatedZipcode);
    const savedAddress = useSelector(getAutoFillAddress);
    const [zipDerivedFromGoogleMaps, setZipDerivedFromGoogleMaps] = useState('');
    const zipValid = useSelector(getIsProductFilterZipcodeValid);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 767px)');

    useEffect(() => {
        setCurrentAddress(userSubmittedZip);
    }, []);

    useEffect(() => {
        if (zipValid) {
            const zipToSet = zipDerivedFromGoogleMaps || (currentAddress.length >= 5 ? currentAddress : '');
            if (zipToSet) {
                dispatch(setUserSubmittedProductFilterZipcode(zipToSet.trimEnd()));
            }
        }
    }, [currentAddress, zipDerivedFromGoogleMaps, userSubmittedZip]);

    const isCandaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);

    const addressFieldOnFocus = () => {
        const qasFailedValue = featureFlags['is-qas-guided-nav-enabled'] ? qasFailed : true;
        setLoadGoogleMaps(qasFailedValue);
        setShowQasModel(true);
    };

    const hideQasModel = (e) => {
        if (e.target?.getAttribute?.('data-element') !== 'address_zip_field') {
            setShowQasModel(false);
        }
    };

    const findPostalCode = (arr) => {
        const postalObj = arr.find((obj) => Object.prototype.hasOwnProperty.call(obj, 'postalCode'));
        if (postalObj?.postalCode) {
            const postalCode = postalObj?.postalCode?.split?.('-');
            return postalCode?.[0] || '';
        }
        return '';
    };

    const getAddressInformation = async (autocomplete) => {
        if (featureFlags['is-qas-guided-nav-enabled'] && !qasFailed) {
            const searchTimeout = featureFlags['qas-address-search-timeout-value'] || 5;
            const timeoutInSecs = searchTimeout * 1000;
            const formatParams = qs.parse(autocomplete.format.substring(autocomplete.format.indexOf('?') + 1));
            const response = await QasClient.qasGet({}, 'format', formatParams, timeoutInSecs, true);
            const error = {};
            try {
                const { data: { Message } } = response;
                if (Message) error.errorMessage = Message;
            } catch (err) {
                console.error(err);
            }
            const getZip = findPostalCode(response?.data?.address);
            if (!getZip) return;
            const reduxAddress = {
                addressComponents: response?.data,
                addressSource: 'QAS',
            };
            dispatch(setUserSelectedAddress(reduxAddress));
            setCurrentAddress(autocomplete?.suggestion);
            dispatch(setAutoFillAddress(autocomplete?.suggestion));
            setShowQasModel(false);
            dispatch(validateProductFilterZipcode(getZip));
            setZipDerivedFromGoogleMaps(getZip);
            const event = {
                target: {
                    value: getZip,
                },
            };
            if (getZip) {
                ziphandleChange('zip')(event);
            }
        } else {
            // called when a user selects an address from gogle maps
            const addressObj = autocomplete?.getPlace?.();
            // filter through data and find the object with zip code
            const getZip = addressObj.address_components?.filter((d) => (d.types[0] === 'postal_code'));
            if (!getZip) return;
            const reduxAddress = {
                addressComponents: addressObj.address_components,
                addressSource: 'google',
            };
            dispatch(setUserSelectedAddress(reduxAddress));
            setCurrentAddress(addressObj?.formatted_address);
            dispatch(setAutoFillAddress(addressObj?.formatted_address));
            dispatch(validateProductFilterZipcode(getZip.length > 0 ? getZip[0].long_name : ''));
            setZipDerivedFromGoogleMaps(getZip.length > 0 ? getZip[0].long_name : '');

            const event = {
                target: {
                    value: getZip,
                },
            };
            if (getZip) {
                ziphandleChange('zip')(event);
            }
        }
    };

    const validateUserSubmittedZipcode = async (event) => {
        // if they do not use the google maps api to select a street address.
        // Remove leading spaces first, then replace multiple spaces with a single space
        let zipCode = event.target.value.trimStart()?.replace?.(/\s+/g, ' ');
        // we used to remove the space to string at end
        const updatedZipcode = zipCode?.trimEnd?.();
        let country = 'USA';
        if ((updatedZipcode?.length === 6 || (updatedZipcode?.length === 7 && !zipValid)) && isCandaZip(updatedZipcode)) {
            // if user enters 6 or 7 chars and is canada zipcode
            const zipSplit = updatedZipcode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            const canadaZipCode = zipSplit.join('');
            zipCode = canadaZipCode;
            dispatch(validateProductFilterZipcode(canadaZipCode));
            country = 'CAN';
        } else if (updatedZipcode?.length === 5 && /^\d+$/.test(updatedZipcode) === true) {
            // if user enters five chars and those chars are all numbers
            dispatch(validateProductFilterZipcode(updatedZipcode));
        } else if (updatedZipcode?.length < 5 && validatedZip !== '') {
            // if there are fewer than 5 chars and there exists a zip in redux clear it
            // we do this so that if someone enters a zipcode, then deletes it the filtering goes away.
            dispatch(clearValidatedZipcode());
        }
        if (updatedZipcode?.length !== 5 && zipValid !== null) {
            // reset the zip is valid flag in redux if zip is not characters.
            dispatch(clearUserSubmittedProductFilterZipcode());
            dispatch(resetProductFilterZipcodeValidity());
        }
        if (zipCode?.length > 0 && featureFlags['is-qas-guided-nav-enabled'] && !qasFailed) {
            const searchTimeout = featureFlags['qas-address-search-timeout-value'] || 5;
            const timeoutInSecs = searchTimeout * 1000;
            let zipResult = {};
            if (country === 'USA' && updatedZipcode.length === 5 && /^\d+$/.test(updatedZipcode) === true) {
                const zipSpecific = await QasClient.qasGet({}, 'search', { query: `${zipCode}-0000`, country, take: 1 }, timeoutInSecs, true);
                zipResult = zipSpecific?.data?.results?.[0] || {};
                zipResult.suggestion = zipResult?.suggestion?.split?.(', ');
                zipResult.suggestion = zipResult?.suggestion?.length > 1 ? zipResult?.suggestion?.[1] : zipResult?.suggestion?.[0];
            }
            QasClient.qasGet({}, 'search', { query: zipCode, country, take: 5 - (zipResult?.suggestion ? 1 : 0) }, timeoutInSecs, true).then((res) => {
                if (res?.data?.results) {
                    setQasSuggestions({
                        count: res?.data?.count || 0,
                        results: [zipResult, ...res?.data?.results],
                    });
                    const results = res?.data?.results || [];
                    const isMatched = results?.some((item) => item.suggestion === zipCode);
                    if (!zipValid && zipCode && isMatched) {
                        getAddressInformation(results[0]);
                    }
                }
            }).catch(() => {
                setQasFailed(true);
                setLoadGoogleMaps(true);
            });
        } else {
            setQasSuggestions({});
            setShowQasModel(false);
        }
        setCurrentAddress(zipCode);
    };

    const handleAddressChange = (e) => {
        // Remove leading spaces first, then replace multiple spaces with a single space
        let zipCodeValue = e.target.value.trimStart()?.replace?.(/\s+/g, ' ');
        // Prevent trailing spaces by trimming at the end
        if (zipCodeValue.endsWith(' ')) {
            zipCodeValue = zipCodeValue.trim();
        }

        setCurrentAddress(zipCodeValue);
        dispatch(setUserSelectedAddress({}));
        dispatch(setAutoFillAddress(''));
        if (!zipCodeValue?.length && zipValid === null) {
            // reset the zip is valid flag in redux if zip is not characters.
            dispatch(clearUserSubmittedProductFilterZipcode());
            dispatch(clearValidatedZipcode());
        }
    };

    // if zipcode is not valid show invalid zip and circle with '!'
    let zipInputRootAddOpac;
    let zipLabelRootWarning;
    let ctZipLabelRootWarning;
    const isAddressLongEnough = currentAddress?.length >= 5;
    const isAddressTooShort = currentAddress?.length < 5;
    const shouldTriggerSubmit = onSubmitCheck === true;
    if ((zipValid === false && isAddressLongEnough && !showQasModel) || (isAddressTooShort && shouldTriggerSubmit)) {
        zipInputRootAddOpac = `${classes.addOpacity}`; // add opacity 1 to ::before
        zipLabelRootWarning = `${classes.zipLabelWarning}`; // add class to show circle
        ctZipLabelRootWarning = `${classes.ctZipErrorText} ${giftFinderFieldStyle.errorMessage}`;
    }

    // if user clicks submit and is empty or less then 5 show error
    let zipInputRootEmptyZip;
    let ctZipInputRootEmptyZip;
    if (onSubmitCheck === true) {
        zipInputRootEmptyZip = `${classes.addOpacity}`;
        ctZipInputRootEmptyZip = `${classes.ctZipErrorText} ${giftFinderFieldStyle.errorMessage}`;
    }

    // if zipcodeIsValid show success check
    let zipLabelRootSuccess;
    if (zipValid === true && currentAddress?.length >= 5) { zipLabelRootSuccess = `${classes.zipLabelSuccess} ${giftFinderFieldStyle.successMessage}`; ctZipInputRootEmptyZip = ''; }

    // input props styling accoding to giftFinderCodeTheoryView value
    let isRootStyle;
    let inputPropStyle;
    if (giftFinderCodeTheoryView) {
        isRootStyle = `${classes.ctInputLabelZipMobile} ${classes.giftFinderField} ${classes.zipLabelRoot} ${ctZipLabelRootWarning} ${zipLabelRootSuccess} ${ctZipInputRootEmptyZip}`;
        inputPropStyle = `${classes.ctSelect} ${zipInputRootEmptyZip} ${zipInputRootAddOpac} ${classes.ctInputMarginTopGift}`;
    } else {
        isRootStyle = `${classes.zipLabelRoot} ${zipLabelRootWarning} ${zipLabelRootSuccess}`;
        inputPropStyle = `${classes.zipInputRoot}  ${zipInputRootAddOpac} ${classes.inputMarginTop}`;
    }

    const handleKeyDown = () => {
        setShowQasModel(true);
    };

    return (
        <>
            <div>
                <TextField
                    fullWidth
                    label={zipLabel}
                    placeholder={isLoadingOrVariableSkip ? '' : zipOrAddressInputPlaceholder}
                    id="zipcode-native-label-placeholder"
                    data-test="hp-zipcode-native-label-placeholder"
                    name="zipcode"
                    defaultValue={userSubmittedZip}
                    onFocus={() => addressFieldOnFocus()}
                    onKeyDown={() => handleKeyDown()}
                    className={giftFinderCodeTheoryView ? '' : classes.paddingRight}
                    InputProps={{
                        inputProps: {
                            'data-element': 'address_zip_field',
                        },
                        disableUnderline: true,
                        classes: {
                            root: inputPropStyle,
                        },

                    }}
                    InputLabelProps={{
                        style: {
                            color: giftFinderBodyTextColor,
                        },
                        shrink: true,
                        classes: {
                            shrink: classes.shrink,
                            root: isRootStyle,
                            focused: giftFinderCodeTheoryView ? classes.zipLabelGiftFinderFocused : classes.zipLabelFocused,
                        },
                    }}
                    onChange={(e) => {
                        handleAddressChange(e);
                        ziphandleChange('zip')(e); // Optionally use handleChange with the name
                        validateUserSubmittedZipcode(e);
                    }}
                    value={savedAddress || currentAddress}
                />
                {showQasModel && qasSuggestions && currentAddress?.length > 0 && <QasSuggestionList handleClickAway={hideQasModel} showModel={showQasModel} handlePickAnAddress={getAddressInformation} suggestions={qasSuggestions} gfAutoFillAddressVariant={gfAutoFillAddressVariant} />}
            </div>
            {loadGoogleMaps ? (
                <GoogleAddressSearch
                    // DO NOT CHANGE ID NAME
                    searchID="data-test-guidedNavZipOrAddressField"
                    getAddressInformation={getAddressInformation}
                />
            ) : null}
        </>
    );
};

AddressZipField.propTypes = {
    zipLabel: string.isRequired,
    zipOrAddressInputPlaceholder: string,
    giftFinderBodyTextColor: string,
    gfAutoFillAddressVariant: bool,
    ziphandleChange: fun,
    giftFinderCodeTheoryView: bool,
    giftFinderFieldStyle: object,
    onSubmitCheck: bool,
    isLoadingOrVariableSkip: bool.isRequired,
};
AddressZipField.defaultProps = {
    zipOrAddressInputPlaceholder: '',
    giftFinderBodyTextColor: '',
    gfAutoFillAddressVariant: false,
    ziphandleChange: () => { },
    giftFinderCodeTheoryView: false,
    giftFinderFieldStyle: {},
    onSubmitCheck: false,
};

export default AddressZipField;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// setup docs: https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete#maps_places_autocomplete-javascript
// types: https://developers.google.com/maps/documentation/places/web-service/supported_types

import { useEffect } from 'react';
import {
    string, func, oneOfType, bool,
} from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const GoogleAddressSearch = (props) => {
    const {
        searchID,
        getAddressInformation,
        abGoogleMapUnfilteredResults,
        currentAddress,
    } = props;

    const ffGeocodeSuggestion = useSelector(getFeatureFlag('is-google-suggestion-address-geocode-enabled'));

    const setZIndex = () => {
        const autoCompleteList = document.querySelectorAll('.pac-container');
        if (autoCompleteList[0]?.style?.zIndex) {
            autoCompleteList[0].style.zIndex = '100000';
        }
        autoCompleteList.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            const autoCompleteElement = item;
            autoCompleteElement.style.zIndex = '1000000';
        });
    };

    useEffect(() => {
        if (!window?.google?.maps?.places || !window?.google?.maps?.event) {
            return;
        }

        let types = ['address'];

        let mapBoundOptions = {};
        if (ffGeocodeSuggestion) {
            types = ['geocode'];
            // Setting US Lat&Lng
            const location = new window.google.maps.LatLng(37.090240, -95.712891);
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(location);
            mapBoundOptions = {
                componentRestrictions: { country: ['us', 'ca'] },
                bounds,
            };
        }

        if (abGoogleMapUnfilteredResults && !ffGeocodeSuggestion) {
            types = [];
        }

        const searchElement = typeof document !== 'undefined' && document.getElementById(searchID);
        const autocomplete = new window.google.maps.places.Autocomplete(searchElement, {
            fields: ['address_components', 'formatted_address', 'name'],
            types,
            ...mapBoundOptions,
        });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => getAddressInformation(autocomplete));

        if (searchElement) {
            searchElement.addEventListener('focus', () => {
                if (searchElement.getAttribute('autocomplete') !== 'new-password') {
                    searchElement.setAttribute('autocomplete', 'new-password');
                    setZIndex();
                }
            });
        }
    }, [abGoogleMapUnfilteredResults]);

    useEffect(() => {
        setZIndex();
    }, [currentAddress]);

    return null;
};

const mapStateToProps = (state) => ({
    abGoogleMapUnfilteredResults: getActiveABTest('google_map_unfiltered_results')(state),
});

GoogleAddressSearch.propTypes = {
    searchID: string.isRequired,
    getAddressInformation: func.isRequired,
    currentAddress: string,
    abGoogleMapUnfilteredResults: oneOfType([bool, string]).isRequired,
};

GoogleAddressSearch.defaultProps = {
    currentAddress: '',
};

export default compose(
    connect(
        mapStateToProps,
    ),
)(GoogleAddressSearch);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SkeletonStyles from './SkeletonStyles';

const styles = (theme) => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    root: { },
    blockBody: {
        width: '160px',
        height: '34px',
        [theme.breakpoints.down(767)]: {
            width: '100%',
        },
    },
});

const AutoFillAddressSkeleton = ({ classes }) => (
    <div className={classes.root}>
        <div className={`${classes.blockBody} ${classes.block}`} />
    </div>
);
AutoFillAddressSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(AutoFillAddressSkeleton);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect } from 'react';

import {
    FormControl, Input, InputLabel, NativeSelect,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { array, func, string } from 'prop-types';
import Calendar from '@material-ui/icons/Event';
import CUSTOM_BREAKPOINTS from '../../../../../helpers/customBreakpoints';

const useStyles = makeStyles((theme) => ({
    dateContainer: {
        flex: '0 1 100%',
        [theme.breakpoints.up(1025)]: {
            '& select': {
                paddingRight: '28px !important',
            },
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flex: '1 0 150px',
            marginRight: '8px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            flex: '0 1 187px',
            marginRight: '16px',
            minWidth: '167px',
        },
    },
    formControlWidth: {
        width: '100%',
    },
    label: {
        color: theme.palette.gfMobileLabelTextColor,
        letterSpacing: '0.42px',
        fontSize: theme.typography?.fontSize || '16px',
        '&$labelFocus': {
            color: theme.palette.gfMobileLabelTextColor,
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                color: theme.palette.white,
            },
        },
        [theme.breakpoints.down(325)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        // tab and desktop
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            color: theme.palette.white,
            fontSize: theme.palette?.text?.fontSize12 || '12px',
            fontWeight: 300,
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
    },
    labelFocus: {},
    shrink: {
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    },
    select: {
        color: theme.palette.gfMobileSelectOptionTextColor,
        letterSpacing: '0.42px',
        borderBottom: theme.palette.gfMobileInputBorderBottom,
        fontSize: theme.typography?.fontSize || '16px',
        '&:focus-visible': {
            backgroundColor: 'rgba(0,0,0,0)',
            color: theme.palette.bgHeader, // TO DO: Need palette primary
            outline: '-webkit-focus-ring-color auto 1px',
            borderRadius: '3px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            height: '2rem',
            backgroundColor: theme.palette.white,
            padding: '0 22px 0 10px',
            fontSize: theme.palette?.text?.fontSize12 || '12px',
            border: theme.palette.gfTabDesktopInputBorder,
            borderRadius: '3px',
            '&:focus': {
                borderRadius: '3px',
                backgroundColor: theme.palette.white,
            },
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
    },
    calIcon: {
        fontSize: theme.palette?.text?.fontSize20 || '20px',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            right: '5px',
            top: '10px',
            fontSize: theme.typography?.fontSize || '16px',
        },
    },
    inputMarginTop: {
        'label + &': {
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                marginTop: '22px',
            },
        },
    },
    ctSelect: {
        [theme.breakpoints.down(768)]: {
            fontSize: theme.palette?.text?.fontSize18 || '18px',
            marginTop: '20px !important',
            background: '#FFF !important',
            border: '1px solid rgb(0, 0, 0)',
            borderRadius: '4px',
            boxShadow: 'none',
            letterSpacing: 'unset',
            '& input, & select': {
                height: '46px',
                padding: '0 12px 2px 12px',
                fontSize: theme.typography?.fontSize || '16px',
                color: '#000',
                borderRadius: '4px',
                border: '1px solid transparent',
                boxShadow: 'none',
                outline: 0,
                '&:focus, &:active': {
                    borderRadius: '4px',
                    border: '1px solid #262626',
                    boxShadow: 'none',
                    outline: 0,
                },
            },
            '& svg': {
                color: '#222222',
                width: '30px',
                height: '30px',
                top: 'calc(50% - 10px)',
            },
            '&.Mui-focused': {
                background: 'transparent',
            },
        },
    },
}));

const GiftfinderDateField = ({
    dateOptions, handleChange, deliveryDateTitle, date, inputLabelColor,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.dateContainer}>
            <FormControl
                fullWidth
                classes={{
                    fullWidth: classes.formControlWidth,
                }}
            >
                <InputLabel
                    shrink
                    htmlFor="arrive-native-label-placeholder"
                    classes={{
                        root: classes.label,
                        shrink: classes.shrink,
                        focused: classes.labelFocus,
                    }}
                    style={{ color: inputLabelColor }}
                >
                    {deliveryDateTitle}
                </InputLabel>
                <NativeSelect
                    disableUnderline
                    value={date}
                    onChange={handleChange('date')}
                    IconComponent={Calendar}
                    classes={{
                        select: classes.select,
                        icon: classes.calIcon,
                    }}
                    className={classes.ctSelect}
                    input={(
                        <Input
                            name="age"
                            id="arrive-native-label-placeholder"
                            data-test="hp-arrive-native-label-placeholder"
                            classes={{
                                root:
                            classes.inputMarginTop,
                            }}
                        />
                    )}
                >
                    {dateOptions.map(
                        (d, idx) => (
                            <option
                                value={d}
                                key={`date${idx + 1}`}
                            >
                                {d}
                            </option>
                        ),
                    )}
                </NativeSelect>
            </FormControl>
        </div>
    );
};

GiftfinderDateField.propTypes = {
    handleChange: func.isRequired,
    dateOptions: array.isRequired,
    inputLabelColor: string.isRequired,
    deliveryDateTitle: string.isRequired,
    date: string.isRequired,
};

export default GiftfinderDateField;
